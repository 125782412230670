.order {
	@extend %section-padding;
	
	&__container {
	}

	&__title {
		@extend %section-title;
	}

	&__wrapper {
		display: flex;
		justify-content: center;
		// @include row;
	}
	&__content {
		@extend %limiter;
		
		// width: 100%;
		// max-width: 500px;

		// @include col(4);
		// @include offset(4);
	}

	
}
