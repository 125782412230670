.edit {
	@extend %section-padding;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		padding-bottom: 0;
	}
	@include xs {
		
	}

	&__container {

	}

	&__row {
		@include row;

		align-items: center;

		.edit__row--reverse & {
			// flex-direction: row-reverse;
		}

		&+& {
			
			@include lg {
				margin-top: 32px;
			}
			@include md {
				
			}
			@include sm {
				
			}
			@include xs {
				
			}
		}
	}

	&__media {
		@include col(5);
		@include offset(1);

		padding-left: 0;


		@include lg {
			@include col(6);
			@include offset(1);

			padding-left: 0;
		}
		@include md {
			
		}
		@include sm {
			@include col(12);
			@include offset(0);
		}
		@include xs {
			// @include col(12);
			// @include offset(0);
		}

		.edit__row--reverse & {
			@include col(5);
			@include offset(1);

			padding-right: 0;
			// @include offset(0);

			@include lg {
				@include col(6);
				@include offset(0);
			}
			@include md {
				
			}
			@include sm {
				@include col(12);
				// @include offset(0);
			}
			@include xs {
				

			}
		}
	}

	&__figure {
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	&__img {
		display: block;
		width: 100%;
	}



	&__content {
		@include col(4);
		@include offset(1);

		// @extend %text-normal;

		@include lg {
			@include col(5);
			@include offset(0);
		}
		@include md {
			
		}
		@include sm {
			@include col(12);

			margin-bottom: 16px;
		}
		@include xs {
			// @include col(12);

			// margin-bottom: 16px;
		}

		.edit__row--reverse & {
			@include col(4);

			order: 2;
			// @include offset(0);

			@include lg {
				@include col(5);
				@include offset(1);
			}
			@include md {
				
			}
			@include sm {
				@include col(12);
				@include offset(0);

				order: 0;
			}
			@include xs {
				// @include col(12);
				// @include offset(0);
				// order: 0;
			}
		}
	}

	&__title {
		@extend %h4;

		margin-bottom: 1em;
	}

	&__text {

		@include lg {
			font-size: 15px;
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			
		}
		p+p {
			margin-top: 1em;
		}
	}
}
