*, *::before, *::after { box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { padding: 0; margin: 0; font: inherit; font-size: 100%; vertical-align: baseline; border: 0; outline: none; -webkit-tap-highlight-color: transparent; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote::before, blockquote::after { content: ""; content: none; }

q::before, q::after { content: ""; content: none; }

table { border-spacing: 0; border-collapse: collapse; }

button { width: auto; padding: 0; margin: 0; overflow: visible; font: inherit; /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */ line-height: normal; /* inherit font & color from ancestor */ color: inherit; background: transparent; border: none; outline: none; /* Corrects font smoothing for webkit */ -webkit-font-smoothing: inherit; -moz-osx-font-smoothing: inherit; /* Corrects inability to style clickable `input` types in iOS */ appearance: none; /* Remove excess padding and border in Firefox 4+ */ }

button::-moz-focus-inner { padding: 0; border: 0; }

a { color: inherit; text-decoration: none; }

input, select, textarea, label { display: inline-block; padding: 0; margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; white-space: normal; vertical-align: middle; background: none; border: 0; border: none; border-radius: 0; box-shadow: none; box-shadow: none; box-shadow: none; appearance: none; }

input::placeholder, select::placeholder, textarea::placeholder, label::placeholder { font-family: inherit; font-size: inherit; color: inherit; }

input:focus, select:focus, textarea:focus, label:focus { outline: 0; }

/* This mixin can be used to set the object-fit: @include object-fit(contain); or object-fit and object-position: @include object-fit(cover, top); */
@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes move-y { 0% { transform: translateY(-15px) rotate(-10deg); }
  50% { transform: translateY(15px) rotate(-10deg); }
  100% { transform: translateY(-15px) rotate(-10deg); } }

@keyframes spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

[data-ae], [data-st], [data-fade] { opacity: 0; will-change: opacity; }

.container { width: 100%; max-width: calc(100% - 96px); margin: 0 auto; }

@media (max-width: 1511px) { .container { max-width: calc(100% - 64px); } }

@media (max-width: 1199px) { .container { max-width: calc(100% - 48px); } }

@media (max-width: 1023px) { .container { max-width: calc(100% - 64px); } }

@media (max-width: 767px) { .container { max-width: calc(100% - 40px); } }

.row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

html { scroll-behavior: smooth; }

body { line-height: 1.4; font-family: "Rubik", helvetica, tahoma, arial, sans-serif; color: #222; }

body ::-moz-selection { color: #fff; background: #F4BFDB; }

body ::selection { color: #fff; background: #F4BFDB; }

@media (max-width: 767px) { main { padding-bottom: 20px; } }

.usp, .pgrid, .product, .reviews, .specs, .order, .edit, .advantages { padding: 80px 0; }

@media (max-width: 1511px) { .usp, .pgrid, .product, .reviews, .specs, .order, .edit, .advantages { padding: 72px 0; } }

@media (max-width: 767px) { .usp, .pgrid, .product, .reviews, .specs, .order, .edit, .advantages { padding: 40px 0; } }

.specs__content, .order__content { width: 100%; max-width: 500px; }

.hero__title, .hero__figure-price, .section-title, .specs__title, .order__title, .paper, .product__title, .product__title-overlay, .section-subtitle, .footer__col-title, .modal__title, .edit__title, .advantages__item-title { font-family: "Rubik", helvetica, tahoma, arial, sans-serif; font-weight: 600; }

.hero__title, .hero__figure-price { font-size: 60px; line-height: 1.15; letter-spacing: -0.04em; }

@media (max-width: 1511px) { .hero__title, .hero__figure-price { font-size: 54px; } }

@media (max-width: 1199px) { .hero__title, .hero__figure-price { font-size: 48px; } }

@media (max-width: 1023px) { .hero__title, .hero__figure-price { font-size: 36px; } }

.section-title, .specs__title, .order__title, .paper, .product__title, .product__title-overlay { font-size: 36px; line-height: 1.15; }

@media (max-width: 1511px) { .section-title, .specs__title, .order__title, .paper, .product__title, .product__title-overlay { font-size: 28px; } }

@media (max-width: 1023px) { .section-title, .specs__title, .order__title, .paper, .product__title, .product__title-overlay { font-size: 32px; } }

@media (max-width: 767px) { .section-title, .specs__title, .order__title, .paper, .product__title, .product__title-overlay { font-size: 24px; } }

.section-subtitle, .footer__col-title, .modal__title, .edit__title, .advantages__item-title { font-size: 24px; letter-spacing: -0.02em; }

@media (max-width: 1511px) { .section-subtitle, .footer__col-title, .modal__title, .edit__title, .advantages__item-title { font-size: 22px; } }

@media (max-width: 1023px) { .section-subtitle, .footer__col-title, .modal__title, .edit__title, .advantages__item-title { font-size: 20px; } }

.btn, .btn-small, .btn-i, .form__button, .product-card__title, .hero__text, .product__text, .reviews__card-text { font-size: 24px; line-height: 1.2; }

@media (max-width: 1511px) { .btn, .btn-small, .btn-i, .form__button, .product-card__title, .hero__text, .product__text, .reviews__card-text { font-size: 22px; } }

@media (max-width: 1023px) { .btn, .btn-small, .btn-i, .form__button, .product-card__title, .hero__text, .product__text, .reviews__card-text { font-size: 20px; } }

@media (max-width: 767px) { .btn, .btn-small, .btn-i, .form__button, .product-card__title, .hero__text, .product__text, .reviews__card-text { font-size: 18px; } }

.usp__item-text, .product-card__price, .modal__thank { font-size: 20px; line-height: 1.2; }

@media (max-width: 767px) { .usp__item-text, .product-card__price, .modal__thank { font-size: 18px; } }

.reviews__card-name { font-size: 16px; }

@media (max-width: 767px) { .reviews__card-name { font-size: 14px; } }

.section-title { margin-bottom: 32px; }

.section-title--center { text-align: center; }

.section-title--no-margin { margin-bottom: 0; }

.section-subtitle { margin-top: 12px; margin-bottom: 32px; }

.section-subtitle--center { text-align: center; }

.section-subtitle--no-margin { margin-bottom: 0; }

@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes move-y { 0% { transform: translateY(-15px) rotate(-10deg); }
  50% { transform: translateY(15px) rotate(-10deg); }
  100% { transform: translateY(-15px) rotate(-10deg); } }

@keyframes spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

[data-ae], [data-st], [data-fade] { opacity: 0; will-change: opacity; }

.btn, .btn-small, .btn-i, .form__button { display: inline-block; white-space: nowrap; cursor: pointer; user-select: none; }

.btn, .btn-small, .btn-i, .form__button { padding: 0 64px; height: 58px; line-height: 58px; text-align: center; background-color: #F4BFDB; transition: background-color .3s; border-radius: 8px; font-weight: 500; }

@media (max-width: 1023px) { .btn, .btn-small, .btn-i, .form__button { padding: 0 48px; height: 50px; line-height: 50px; } }

@media (max-width: 767px) { .btn, .btn-small, .btn-i, .form__button { width: 100%; } }

.btn:hover, .btn-small:hover, .btn-i:hover, .form__button:hover { background-color: rgba(244, 191, 219, 0.8); }

.btn-small { font-size: 16px; padding: 0 24px; height: 40px; line-height: 40px; }

@media (max-width: 767px) { .btn-small { padding: 0 18px; } }

.btn-i { display: inline-flex; align-items: center; justify-content: center; }

.btn-i span { position: relative; display: block; transition: transform .3s; }

.btn-i:hover span { transform: translateX(0.75em); }

.btn-i:hover svg { opacity: 1; }

.btn-i svg { position: absolute; top: 50%; right: 100%; display: block; width: 1.2em; max-height: 1.5em; margin-right: 0.3em; transform: translateY(-50%); opacity: 0; transition: opacity .3s; }

.btn-i svg path { stroke: currentColor; }

.richtext *:first-child { margin-top: 0; }

.richtext *:last-child { margin-bottom: 0; }

.richtext h1 { margin: 1em 0; }

.richtext h2 { margin: 1em 0; }

.richtext h3 { margin: 1em 0; }

.richtext h5 { margin: 1em 0; }

.richtext h6 { margin: 1em 0; }

.richtext p { margin-top: 1em; margin-bottom: 1em; }

.richtext li { margin-bottom: .5em; }

.richtext li:last-child { margin-bottom: 0; }

.richtext b, .richtext strong { font-weight: bold; }

.richtext i, .richtext em { font-style: italic; }

.richtext img, .richtext video { margin: 1em auto; }

.richtext table { width: 100%; }

.footer { position: sticky; bottom: 0; left: 0; z-index: 1; background-color: #333; padding: 60px 0 40px; color: #fff; }

@media (max-width: 767px) { .footer { padding: 32px 0 32px; } }

.footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; display: flex; align-items: flex-start; justify-content: center; user-select: none; }

@media (max-width: 1023px) { .footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 25%; } }

@media (max-width: 767px) { .footer__logo-wrapper { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-bottom: 24px; } }

.footer__logo { max-width: 80px; user-select: none; }

@media (max-width: 767px) { .footer__logo { max-width: 50px; } }

.footer__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

.footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; }

@media (max-width: 1023px) { .footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 37.5%; } }

@media (max-width: 767px) { .footer__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-bottom: 40px; text-align: center; } }

.footer__col-title { margin-bottom: 12px; }

.footer__nav-list { margin-top: 20px; }

.footer__nav-item + .footer__nav-item { margin-top: 10px; }

.footer__nav-link { transition: color .3s; }

.footer__nav-link:hover { color: #F4BFDB; }

.footer__copy { font-size: 0.8em; margin-top: 32px; text-align: center; }

@media (max-width: 767px) { .footer__copy { margin-top: 16px; } }

.social { display: flex; align-items: center; user-select: none; }

@media (max-width: 767px) { .social { justify-content: center; } }

.social__item { user-select: none; }

.social__item + .social__item { margin-left: 16px; }

.social__link { display: block; transition: opacity .3s, transform .3s; }

.social__link:hover { opacity: 0.8; transform: scale(1.1); }

.social__icon { display: block; width: 24px; height: auto; pointer-events: none; }

@media (max-width: 1023px) { .social__icon { width: 32px; } }

main { position: relative; z-index: 2; background-color: #fff; }

.usp__title { margin-bottom: 32px; }

.usp__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

.usp__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; text-align: center; }

@media (max-width: 767px) { .usp__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; }
  .usp__item + .usp__item { margin-top: 32px; } }

.usp__item-box { display: flex; align-items: center; justify-content: center; font-size: 80px; margin: 0 auto 16px; width: 1.1em; height: 1em; }

@media (max-width: 1511px) { .usp__item-box { font-size: 70px; } }

@media (max-width: 1199px) { .usp__item-box { font-size: 60px; } }

@media (max-width: 1023px) { .usp__item-box { font-size: 60px; } }

@media (max-width: 767px) { .usp__item-box { font-size: 56px; width: 1.1em; margin-bottom: 8px; } }

.usp__item-icon { display: block; width: 100%; height: 100%; user-select: none; object-fit: contain; }

.usp__item-text { font-family: "Rubik", helvetica, tahoma, arial, sans-serif; max-width: 450px; margin: 0 auto; }

.pgrid__title { margin-bottom: 32px; }

.pgrid__row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

@media (max-width: 1023px) { .pgrid__row { margin-bottom: -32px; } }

.pgrid__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 25%; }

@media (max-width: 1023px) { .pgrid__col { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-bottom: 32px; } }

.product-card__figure { aspect-ratio: 1; border-radius: 16px; margin-bottom: 16px; user-select: none; overflow: hidden; }

@media (max-width: 767px) { .product-card__figure { margin-bottom: 12px; } }

.product-card__img { width: 100%; height: 100%; object-fit: cover; }

.product-card__title { font-family: "Rubik", helvetica, tahoma, arial, sans-serif; margin-bottom: 12px; }

@media (max-width: 767px) { .product-card__title { margin-bottom: 8px; } }

.product-card__button-wrapper { margin-top: 16px; }

.modal { position: fixed; z-index: 100; top: 0; left: 0; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; opacity: 0; pointer-events: none; transition: opacity .3s, transform .3s; }

.modal--visible { opacity: 1; pointer-events: all; }

.modal__overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 1; }

.modal__body { position: relative; z-index: 2; width: 500px; max-width: 95%; background-color: #fff; padding: 24px; border-radius: 16px; transition: opacity .3s; transform: translateY(40px) scale(0.96); transition: transform .3s; }

.modal--visible .modal__body { transform: translateY(0) scale(1); }

.modal__content { transition: opacity .3s; }

.modal--loading .modal__content { opacity: 0.4; pointer-events: none; user-select: none; }

.modal__loader { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2; user-select: none; display: none; pointer-events: none; }

.modal--loading .modal__loader { display: block; }

.modal__close { font-size: 24px; position: absolute; top: 16px; right: 16px; width: 1em; height: 1em; font-size: 24px; cursor: pointer; user-select: none; }

.modal__close:after, .modal__close:before { content: ''; position: absolute; top: 50%; left: 50%; width: 100%; height: 2px; background-color: currentColor; opacity: 0.85; }

.modal__close:after { transform: translate(-50%, -50%) rotate(45deg); }

.modal__close:before { transform: translate(-50%, -50%) rotate(-45deg); }

.modal__title { margin-bottom: 16px; }

.modal__text { line-height: 1.5; }

.modal__form { margin-top: 24px; }

.modal__thank { max-height: 0; opacity: 0; text-align: center; padding: 0 28px; transition: opacity .1s, margin 0.1s; user-select: none; pointer-events: none; }

.modal--thank .modal__thank { opacity: 1; max-height: 150px; margin-top: 16px; transition: opacity .5s, margin .3s; }

.form { display: flex; flex-direction: column; align-items: flex-start; transition: opacity .15s; }

.form.is-loading { opacity: 0.5; pointer-events: none; user-select: none; }

.form__label { width: 100%; font-size: 0.8em; }

.form__input { width: 100%; display: block; margin: 4px 0 24px; padding: 4px 0px; border-bottom: 1px solid #F4BFDB; transition: border .2s; }

.form__input::placeholder { opacity: 0.6; }

.form__input:focus { border-bottom: 1px solid #e97eb6; }

.form__button-wrapper { display: flex; justify-content: center; width: 100%; }

.form__button { user-select: none; cursor: pointer; }

.form__thank { max-height: 0; opacity: 0; width: 100%; font-weight: 500; text-align: center; transition: opacity .1s, margin 0.1s; user-select: none; pointer-events: none; }

.form.is-thank .form__thank { opacity: 1; max-height: 70px; margin-top: 16px; transition: opacity .5s, margin .3s; }

.loader { font-size: 48px; width: 1em; height: 1em; border-radius: 100%; overflow: hidden; border: 0.1em solid #F4BFDB; border-right-color: white; animation: spin 3s infinite linear; }

.bar { --shadow-color: 0deg 0% 63%; --shadow-elevation-medium:
		 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
		 0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
		 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
		 5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36); box-shadow: var(--shadow-elevation-medium); position: fixed; bottom: 24px; right: 24px; overflow: hidden; padding: 12px 16px; background-color: #fff; z-index: 10; border-radius: 8px; transition: transform .3s; }

@media (max-width: 767px) { .bar { --shadow-elevation-medium:
			0px -0.2px 0.2px hsl(var(--shadow-color) / 0.36),
			0px -0.8px 0.9px -0.8px hsl(var(--shadow-color) / 0.36),
			0px -1.9px 2.1px -1.7px hsl(var(--shadow-color) / 0.36),
			0px -4.7px 5.3px -2.5px hsl(var(--shadow-color) / 0.36); width: 100%; max-width: 100%; right: 0; bottom: 0; padding: 8px 8px; border-radius: 0; } }

.bar--hidden { transform: translateY(130%); pointer-events: none; }

@media (max-width: 767px) { .bar--hidden { transform: translateY(110%); } }

.bar__inner { display: flex; align-items: center; }

.bar__figure { width: 1em; height: 1em; border-radius: 10px; user-select: none; background-color: #f4e6bf; padding: 0.1em; font-size: 48px; }

@media (max-width: 767px) { .bar__figure { font-size: 36px; } }

.bar__img { display: block; width: 100%; height: 100%; object-fit: contain; pointer-events: none; }

.bar__main { display: flex; align-items: center; margin-left: 1em; }

@media (max-width: 767px) { .bar__main { margin-left: 0.8em; font-size: 14px; } }

@media (max-width: 767px) { .bar__title span { display: none; } }

.bar__price { padding: 2px 5px; border-radius: 3px; background-color: rgba(244, 191, 219, 0.2); border-radius: 4px; margin-left: 1em; font-size: 15px; }

.bar__button-wrapper { margin-left: 24px; }

@media (max-width: 767px) { .bar__button-wrapper { margin-left: auto; } }

.hero { position: relative; padding: 90px 0 48px; overflow: hidden; }

@media (max-width: 1511px) { .hero { padding-top: 130px; } }

@media (max-width: 1023px) { .hero { padding-top: 80px; } }

.hero:after { content: ''; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; z-index: -1; user-select: none; background: #f4e6bf; background: linear-gradient(318deg, rgba(244, 230, 191, 0.4) 0%, rgba(244, 230, 191, 0.25) 100%); }

@media (max-width: 767px) { .hero { padding: 60px 0 32px; padding-top: 100px; } }

.hero__logo { position: absolute; top: 16px; left: 50%; transform: translateX(-50%); display: block; width: 40px; height: auto; z-index: 1; user-select: none; }

@media (max-width: 767px) { .hero__logo { top: 12px; width: 36px; } }

.hero__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); align-items: center; }

.hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; }

@media (max-width: 1511px) { .hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; } }

@media (max-width: 1023px) { .hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; } }

@media (max-width: 767px) { .hero__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; order: 2; margin-top: 32px; }
  [dir="rtl"] .hero__content { margin-right: 0; margin-left: initial; } }

.hero__title { position: relative; margin-bottom: 16px; }

@media (max-width: 767px) { .hero__title { position: static; font-size: 26px; margin-top: 16px; } }

.hero__cat { position: absolute; bottom: 105%; left: 3%; user-select: none; transform: translateY(-15px); animation: move-y 4s infinite ease-in-out; }

@media (max-width: 1511px) { .hero__cat { bottom: 103%; } }

@media (max-width: 767px) { .hero__cat { width: 30vw; top: 15%; left: 5%; z-index: 1; } }

.hero__icon { position: absolute; bottom: 0; left: 0; width: 10vw; height: auto; opacity: 1; transition: opacity 0.2s; pointer-events: none; user-select: none; }

@media (max-width: 767px) { .hero__icon { width: 100%; } }

.hero--buy .hero__icon--normal { opacity: 0; transition: opacity 0.2s 0.2s; }

.hero__icon--alt { opacity: 0; transition: opacity 0.2s 0.2s; }

.hero--buy .hero__icon--alt { opacity: 1; transition: opacity 0.2s; }

.hero__text { margin-bottom: 16px; }

.hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; margin-left: 8.33333%; }

[dir="rtl"] .hero__figure { margin-right: 8.33333%; margin-left: initial; }

@media (max-width: 1023px) { .hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-left: 0; }
  [dir="rtl"] .hero__figure { margin-right: 0; margin-left: initial; } }

@media (max-width: 767px) { .hero__figure { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; }
  [dir="rtl"] .hero__figure { margin-right: 0; margin-left: initial; } }

.hero__figure-price { position: absolute; top: 5%; left: 100%; display: block; border-radius: 200px; background-color: #F4BFDB; padding: 0.12em 0.35em; border: 2px solid #ed93c3; transform: translateX(-50%); opacity: 0.85; }

@media (max-width: 1023px) { .hero__figure-price { left: unset; right: 0; transform: none; } }

@media (max-width: 767px) { .hero__figure-price { top: unset; bottom: 80%; left: unset; right: 0px; transform: none; } }

.hero__figure-inner { position: relative; user-select: none; }

.hero__figure-paper { position: absolute; top: 50.6%; left: 49%; width: 70%; max-height: 55%; transform: translate(-50%, 0%); overflow: hidden; }

.hero__figure-paper:before { content: ''; position: absolute; top: 0%; left: 0; display: block; width: 100%; height: 20px; background-color: red; border-radius: 0  0 50% 50%; background: linear-gradient(180deg, #171417 0%, rgba(23, 20, 23, 0) 100%); opacity: 0.2; filter: blur(1px); z-index: 2; }

.hero__img { display: block; width: 100%; }

.hero__figure-circle { display: none !important; position: absolute; top: 60%; left: 50%; width: 30%; border-radius: 100%; border: 5px solid #F4BFDB; opacity: 0; user-select: none; pointer-events: none; }

.hero__figure-circle:after { content: ''; display: block; width: 50%; padding-top: 100%; }

.hero__aside { position: absolute; display: flex; font-family: "Rubik", helvetica, tahoma, arial, sans-serif; right: 0; top: 50%; font-size: 120px; transform: rotate(-90deg) translate(50%, -10%); transform-origin: right; color: #F4BFDB; user-select: none; }

@media (max-width: 1023px) { .hero__aside { display: none; } }

.hero__aside-letter { display: inline-block; }

.paper { position: relative; background-color: #fafafa; padding: 32px 24px 10px; transform-origin: 80% 0; user-select: none; pointer-events: none; line-height: 1; letter-spacing: -0.02em; transform: translateY(-100%); will-change: transform; }

@media (max-width: 1023px) { .paper { padding: 24px 16px 5px; } }

@media (max-width: 767px) { .paper { font-size: 20px; padding: 24px 16px 5px; } }

.paper:after { content: ''; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; user-select: none; background-image: url("../img/grain-3.png"); opacity: 0.6; }

.paper:before { content: ''; position: absolute; top: 100%; left: 0; height: 4px; width: 100%; background-image: url("../img/triangle-2.png"); }

.paper__text { filter: grayscale(1); }

.paper__text + .paper__text { margin-top: 0.7em; }

.product__row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); align-items: flex-start; }

.product__row--reversed { flex-direction: row-reverse; }

.product__gallery { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; }

@media (max-width: 767px) { .product__gallery { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; gap: 8px; order: 2; } }

.product__gallery + .product__gallery { margin-top: 16px; }

@media (max-width: 767px) { .product__gallery + .product__gallery { margin-top: 8px; } }

.product__gallery-grid { display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(2, 1fr); gap: 16px; }

.product__gallery-grid + .product__gallery-grid { margin-top: 16px; }

@media (max-width: 767px) { .product__gallery-grid + .product__gallery-grid { margin-top: 8px; } }

.product__col-wide { position: relative; z-index: 2; }

.product__figure { position: relative; display: block; overflow: hidden; cursor: pointer; border-radius: 10px; }

.bp-html .product__figure { border-radius: 0; height: 100%; pointer-events: none; }

.product__figure:nth-child(1) { grid-column: span 2; grid-row: span 2; }

.product__gallery-grid--reversed .product__figure:nth-child(1) { grid-column: span 1; grid-row: span 1; }

.product__gallery-grid--reversed .product__figure:nth-child(2) { grid-column: span 2; grid-row: span 2; }

.product__figure:after { content: ''; position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; user-select: none; background-color: #000; z-index: 1; border-radius: inherit; }

@media (max-width: 767px) { .product__figure:after { display: none; } }

.product__figure:hover svg { opacity: 1; transform: translate(-50%, -50%) scale(1); }

.product__figure svg { position: absolute; top: 50%; left: 50%; display: block; transform: translate(-50%, -50%) scale(0.9); width: 60px; height: auto; user-select: none; opacity: 0; transition: opacity	.3s, transform .3s; z-index: 3; }

@media (max-width: 1023px) { .product__figure svg { width: 48px; } }

@media (max-width: 767px) { .product__figure svg { width: 40px; } }

.product__figure svg path { fill: #fff; }

.bp-html .product__figure svg { display: none; user-select: none; pointer-events: none; }

.product__img { position: relative; display: block; width: 100%; height: 100%; object-fit: cover; user-select: none; transition: opacity .3s, transform .3s; transform: scale(1.0); will-change: transform; border-radius: inherit; z-index: 2; }

.product__figure:hover .product__img { opacity: 0.75; transform: scale(1.03); }

.bp-html .product__figure:hover .product__img { opacity: 1; transform: scale(1); }

.product__col-narrow .product__figure + .product__figure { margin-top: auto; }

.product__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; position: sticky; top: 100px; }

@media (max-width: 767px) { .product__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; position: static; margin-bottom: 32px; } }

.product__title-wrapper { position: relative; margin-bottom: 24px; display: inline-block; }

.product__title-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 110%; color: #F4BFDB; user-select: none; pointer-events: none; clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }

@media (max-width: 767px) { .product__title-overlay { display: none; } }

.product__text p + p { margin-top: 0.8em; }

.product__text strong { font-weight: 600; }

.product__icon { display: inline-block; transform: translateY(0.1em); user-select: none; }

.product__icon img { display: block; width: 0.9em; height: auto; margin-bottom: 0.05em; }

.product__icon svg { display: block; width: 1em; height: auto; }

.product__icon svg path { fill: #F4BFDB; }

.product__btn-wrapper { margin-top: 24px; }

.bp-lock body { overflow: unset !important; }

.reviews { position: relative; z-index: -1; background-color: #bff4f1; }

.reviews__inner { overflow: hidden; }

.reviews__title { margin-bottom: 32px; }

.reviews__card { --shadow-color: 0deg 0% 60%; --shadow-elevation-medium:
		0.2px 0.6px 0.8px hsl(var(--shadow-color) / 0.32),
		0.9px 2px 2.6px -0.7px hsl(var(--shadow-color) / 0.35),
		2.1px 4.9px 6.4px -1.4px hsl(var(--shadow-color) / 0.39),
		4.9px 11.5px 14.9px -2px hsl(var(--shadow-color) / 0.42); box-shadow: var(--shadow-elevation-medium); display: flex; flex-direction: column; align-items: center; text-align: center; background-color: #fff; border-radius: 12px; padding: 24px 16px; }

@media (max-width: 767px) { .reviews__card { padding: 16px; } }

.reviews__card-frame { width: 1em; height: 1em; font-size: 48px; border-radius: 100px; overflow: hidden; margin-bottom: 14px; user-select: none; }

.reviews__card-image { display: block; width: 100%; height: 100%; object-fit: cover; pointer-events: none; user-select: none; }

.reviews__card-text { max-width: 450px; margin: 0 auto 12px; font-style: italic; }

@media (max-width: 767px) { .reviews .splide { padding: 0 32px; } }

.reviews .splide__list { display: flex; }

.reviews .splide__slide { width: 25%; flex-shrink: 0; padding: 0 24px; }

@media (max-width: 1511px) { .reviews .splide__slide { width: 33.3333%; padding: 0 16px; } }

@media (max-width: 1199px) { .reviews .splide__slide { padding: 0 12px; } }

@media (max-width: 1023px) { .reviews .splide__slide { width: 50%; } }

@media (max-width: 767px) { .reviews .splide__slide { width: 100%; } }

.reviews .splide__pagination { display: flex; align-items: center; justify-content: center; margin-top: 32px; user-select: none; list-style: none; }

@media (max-width: 1023px) { .reviews .splide__pagination { margin-top: 24px; } }

.reviews .splide__pagination__page { position: relative; display: block; font-size: 10px; width: 1em; height: 1em; margin: 0 0.4em; border-radius: 100%; background-color: #F4BFDB; opacity: 0.6; cursor: pointer; }

.reviews .splide__pagination__page.is-active { opacity: 1; }

.reviews .splide__pagination__page:after { content: ''; position: absolute; top: 50%; left: 50%; display: block; width: 160%; height: 160%; transform: translate(-50%, -50%); user-select: none; }

.reviews .splide__sr { display: none !important; }

.bgs { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; user-select: none; pointer-events: none; }

.bgs__item { position: absolute; left: 0; width: 100%; }

.bgs__item---top { bottom: 95%; }

.bgs__item---bottom { top: 95%; }

.bgs__item---bottom img { transform: rotate(180deg); }

.bgs__item-img { display: block; width: 100%; }

.specs__wrapper { display: flex; justify-content: center; }

.specs__row { display: flex; }

.specs__row + .specs__row { margin-top: 0.6em; }

.specs__col:nth-of-type(2n+1) { display: flex; align-items: baseline; flex-grow: 1; margin-right: auto; }

.specs__col:nth-of-type(2n+1):after { content: ''; display: block; flex-grow: 1; border-bottom: 2px dotted currentColor; opacity: 0.35; margin: 0 0.2em; pointer-events: none; user-select: none; }

.specs__col:nth-of-type(2n) { font-weight: 500; }

.order__wrapper { display: flex; justify-content: center; }

@media (max-width: 1023px) { .edit { padding-bottom: 0; } }

.edit__row { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); align-items: center; }

@media (max-width: 1511px) { .edit__row + .edit__row { margin-top: 32px; } }

.edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; margin-left: 8.33333%; padding-left: 0; }

[dir="rtl"] .edit__media { margin-right: 8.33333%; margin-left: initial; }

@media (max-width: 1511px) { .edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-left: 8.33333%; padding-left: 0; }
  [dir="rtl"] .edit__media { margin-right: 8.33333%; margin-left: initial; } }

@media (max-width: 1023px) { .edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; }
  [dir="rtl"] .edit__media { margin-right: 0; margin-left: initial; } }

.edit__row--reverse .edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; margin-left: 8.33333%; padding-right: 0; }

[dir="rtl"] .edit__row--reverse .edit__media { margin-right: 8.33333%; margin-left: initial; }

@media (max-width: 1511px) { .edit__row--reverse .edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 50%; margin-left: 0; }
  [dir="rtl"] .edit__row--reverse .edit__media { margin-right: 0; margin-left: initial; } }

@media (max-width: 1023px) { .edit__row--reverse .edit__media { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; } }

.edit__figure { user-select: none; }

@media (max-width: 767px) { .edit__figure { margin-left: -20px; margin-right: -20px; } }

.edit__img { display: block; width: 100%; }

.edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; margin-left: 8.33333%; }

[dir="rtl"] .edit__content { margin-right: 8.33333%; margin-left: initial; }

@media (max-width: 1511px) { .edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; margin-left: 0; }
  [dir="rtl"] .edit__content { margin-right: 0; margin-left: initial; } }

@media (max-width: 1023px) { .edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-bottom: 16px; } }

.edit__row--reverse .edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; order: 2; }

@media (max-width: 1511px) { .edit__row--reverse .edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 41.66667%; margin-left: 8.33333%; }
  [dir="rtl"] .edit__row--reverse .edit__content { margin-right: 8.33333%; margin-left: initial; } }

@media (max-width: 1023px) { .edit__row--reverse .edit__content { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; order: 0; }
  [dir="rtl"] .edit__row--reverse .edit__content { margin-right: 0; margin-left: initial; } }

.edit__title { margin-bottom: 1em; }

@media (max-width: 1511px) { .edit__text { font-size: 15px; } }

.edit__text p + p { margin-top: 1em; }

.advantages { background-color: rgba(244, 230, 191, 0.3); }

.advantages__grid { --bs-gutter-x: 30px; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x) / -2); margin-left: calc(var(--bs-gutter-x) / -2); }

.advantages__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 33.33333%; display: flex; }

@media (max-width: 1023px) { .advantages__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 66.66667%; margin-left: 16.66667%; }
  [dir="rtl"] .advantages__item { margin-right: 16.66667%; margin-left: initial; }
  .advantages__item + .advantages__item { margin-top: 24px; } }

@media (max-width: 767px) { .advantages__item { flex-shrink: 0; width: 100%; max-width: 100%; padding-right: calc(var(--bs-gutter-x) / 2); padding-left: calc(var(--bs-gutter-x) / 2); margin-top: var(--bs-gutter-y); flex: 0 0 auto; width: 100%; margin-left: 0; }
  [dir="rtl"] .advantages__item { margin-right: 0; margin-left: initial; } }

.advantages__item-box { user-select: none; }

.advantages__item-icon { width: 48px; margin-right: 1em; }

.advantages__main { max-width: 400px; }

.advantages__item-text { width: 90%; }

@media (max-width: 767px) { .advantages__item-text { width: 100%; } }
