.advantages {
	@extend %section-padding;

	background-color: rgba($c-yellow, .3);

	&__container {
		
	}

	&__title {
		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			// margin-bottom: 4px;
		}
	}

	&__grid {
		@include row;
	}

	&__item {
		@include col(4);

		display: flex;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(8);
			@include offset(2);
			&+& {
				// margin-top: 16px;
				margin-top: 24px;
			}
		}
		@include xs {
			@include col(12);
			@include offset(0);
		}
	}

	&__item-box {
		user-select: none;
	}

	&__item-icon {
		width: 48px;
		margin-right: 1em;
	}

	&__main {
		max-width: 400px;
	}

	&__item-title {
		@extend %h4;
	}

	&__item-text {
		width: 90%;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			width: 100%;
		}
	}
}
.section-title {

	&--center {
	}
}
