.hero {
	position: relative;
	padding: 90px 0 48px;
	overflow: hidden;

	
	@include lg {
		padding-top: 130px;
	}
	@include md {
		
	}
	@include sm {
		padding-top: 80px;
	}
	@include xs {
		
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		z-index: -1;
		user-select: none;


		// background-color: rgba($c-accent, .1);
		// background: linear-gradient(220.55deg, #FFDC99 0%, #FF62C0 100%);
		// background: linear-gradient(220.55deg, #FFF6EB 0%, #DFD1C5 100%);
		// background: linear-gradient(220.55deg, #FFEB3A 0%, #4DEF8E 100%);
		background: rgb(244,230,191);
		background: linear-gradient(318deg, rgba(244,230,191,.4) 0%, rgba(244,230,191,0.25) 100%);
		// opacity: 0.5;
		// opacity: 1;
	}

	.hero--buy {

	}

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		padding: 60px 0 32px;

		padding-top: 100px;
	}

	&__logo {
		position: absolute;
		top: 16px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 40px;
		height: auto;
		z-index: 1;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			top: 12px;
			width: 36px;
		}
	}
	&__container {
	}

	&__grid {
		@include row();

		align-items: center;
	}

	&__content {
		@include col(5);

		@include lg {
			@include col(6);
		}
		@include md {
			
		}
		@include sm {
			@include col(6);
		}
		@include xs {
			@include col(12);
			@include offset(0);

			order: 2;
			// margin-top: 24px;
			margin-top: 32px;
		}

	}

	&__title {
		@extend %h1;

		position: relative;
		margin-bottom: 16px;
		
		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			position: static;
			font-size: 26px;
			margin-top: 16px;
		}
	}

	&__cat {
		position: absolute;
		bottom: 105%;
		left: 3%;
		user-select: none;
		transform: translateY(-15px);
		// transform: rotate(-10deg);

		animation: move-y 4s infinite ease-in-out;

		@include lg {
			bottom: 103%;
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			width: 30vw;
			top: 15%;
			left: 5%;
			z-index: 1;
		}

	}

	&__icon {
		$icon-timing: .2s;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 10vw;
		height: auto;
		opacity: 1;
		// transition: opacity .1s;
		transition: opacity $icon-timing;
		pointer-events: none;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			width: 100%;
		}

		&--normal {
			// .hero__title:hover & {

			.hero--buy & {
				opacity: 0;
				transition: opacity $icon-timing $icon-timing;
			}
		}

		&--alt {
			opacity: 0;
			transition: opacity $icon-timing $icon-timing;

			// .hero__title:hover & {
			.hero--buy & {
				opacity: 1;
				transition: opacity $icon-timing;
			}
		}
	}


	&__text {
		// @extend %text-normal;
		@extend %text-mid;

		margin-bottom: 16px;
	}

	&__btn-wrapper {
	}

	&__btn {
	}




	&__figure {
		@include col(4);
		@include offset(1);

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			@include col(6);
			@include offset(0);
		}
		@include xs {
			@include col(12);
			@include offset(0);
		}
	}

	&__figure-price {
		@extend %h1;

		position: absolute;
		top: 5%;
		left: 100%;
		display: block;
		// border-radius: 0.5em;
		border-radius: 200px;
		background-color: $c-accent;
		padding: 0.12em 0.35em;
		border: 2px solid darken($c-accent, 10%);

		transform: translateX(-50%);
		opacity: 0.85;
		// font-size: 100px;
		// font-weight: bold;
		// 
		
		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			left: unset;
			right: 0;
			transform: none;
		}
		@include xs {
			top: unset;
			// bottom: 5%;
			bottom: 80%;
			left: unset;
			right: 0px;
			transform: none;

		}
	}
	&__figure-inner {
		position: relative;
		user-select: none;
	}

	&__figure-paper {
		position: absolute;
		top: 50.6%;
		left: 49%;
		width: 70%;
		// height: 55%;
		max-height: 55%;
		transform: translate(-50%, 0%);
		overflow: hidden;
		// opacity: 0.8;

		&:before {
			content: '';
			position: absolute;
			top: 0%;
			left: 0;
			display: block;
			width: 100%;
			height: 20px;
			background-color: red;
			border-radius: 0  0 50% 50%;
			background: linear-gradient(180deg, rgba(23,20,23,1) 0%, rgba(23,20,23,0) 100%);
			opacity: 0.2;
			filter: blur(1px);

			z-index: 2;
			
		}
	}

	&__img {
		display: block;
		width: 100%;
	}

	&__figure-circle {
		display: none !important;

		position: absolute;
		top: 60%;
		left: 50%;
		width: 30%;
		border-radius: 100%;
		border: 5px solid $c-accent;
		opacity: 0;
		user-select: none;
		pointer-events: none;

		&:after {
			content: '';
			display: block;
			width: 50%;
			padding-top: 100%;
		}
	}

	&__aside {
		position: absolute;
		// display: block;
		display: flex;
		font-family: $font-title;
		right: 0;
		top: 50%;
		font-size: 120px;
		transform: rotate(-90deg) translate(50%, -10%);
		transform-origin: right;
		color: $c-accent;
		user-select: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			// font-size: 70px;
			display: none;
		}
		@include xs {
			
		}
	}

	&__aside-letter {
		display: inline-block;
	}

}


.paper {
	@extend %h2;

	position: relative;
	background-color: #fafafa;
	// padding: 32px 32px 20px;
	padding: 32px 24px 10px;
	transform-origin: 80% 0;
	user-select: none;
	pointer-events: none;

	line-height: 1;
	letter-spacing: -0.02em;
	transform: translateY(-100%);
	will-change: transform;

	
	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		padding: 24px 16px 5px;
	}
	@include xs {
		font-size: 20px;
		padding: 24px 16px 5px;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 100%;
		user-select: none;
		// background-image: url('../img/grain-2.png');
		background-image: url('../img/grain-3.png');
		opacity: 0.6;

		// opacity: 1;
	}

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		height: 4px;
		width: 100%;
		// background-color: rgba(#0f0, .3);
		// background-image: url('../img/triangle.png');
		background-image: url('../img/triangle-2.png');

	}

	&__text {
		// margin-bottom: 1em;
		filter: grayscale(1);
		// line-height: 1.1;

		&+& {
			margin-top: 0.7em;
		}
	}
}