.specs {
	@extend %section-padding;
	
	&__container {
	}

	&__title {
		@extend %section-title;
	}

	&__wrapper {
		display: flex;
		justify-content: center;
		// @include row;
	}
	&__content {
		@extend %limiter;
		
		// width: 500px;

		// @include col(4);
		// @include offset(4);
	}

	&__list {
		// @extend %text-normal;
	}

	&__row {
		display: flex;
		// margin-bottom: 0.6em;

		&+& {
			margin-top: 0.6em;
		}
	}

	&__col {


		&:nth-of-type(2n+1) {
			display: flex;
			align-items: baseline;
			// padding-right: 1em;
			// margin-right: 1em;

			// opacity: 0.2;

			flex-grow: 1;
			margin-right: auto;

			&:after {
				content: '';
				display: block;
				flex-grow: 1;
				// background-color: red;
				border-bottom: 2px dotted currentColor;
				// opacity: 0.5;
				opacity: 0.35;
				margin: 0 0.2em;
				pointer-events: none;
				user-select: none;
				// margin-left: 0.2em;
				// margin-right: 0.2em;
			}
		}
		&:nth-of-type(2n) {
			font-weight: 500;
		}
		

		
	}
}
.section-title {

	&--center {
	}
}
