// breakpoints (desktop first)
// $b-mobile-mini: 479px;
// $b-mobile-midi: 639px;
// $b-mobile: 700px;
// $b-tablet: 1050px;
// $b-tablet-v: 850px;
// $b-tablet-maxi: 1055px;
// $b-labtop-mini: 1299px;
// $b-laptop: 1400px;
// $b-desktop: 1899px;
// $b-desktop-maxi: 2239px;

//fonts

$font-notosans: "NotoSans", helvetica, tahoma, arial, sans-serif;
$font-prata: "Prata", Georgia, Garamond, "Times New Roman", Times, serif;
$font-rubik: "Rubik", helvetica, tahoma, arial, sans-serif;


$font-default: $font-rubik;
$font-title: $font-rubik;


//colors
$c-black: #222;
$c-white: #fff;
$c-choco-100: #322621;
$c-choco-80: #574640;
$c-choco-60: #a9a5a3;
$c-choco-20: #d4d2d1;
// $c-oyster: #fffdf7;
$c-oyster: #fffdf6;
// $c-yellow: #ecca89;


$c-sand: #F1DCB5;
$c-orange: #FEB032;
$c-orange--light: #fdf1e1;


// https://www.sessions.edu/color-calculator-results/?colors=f4bfdb,c1f4bf,f4f3bf
// https://www.sessions.edu/color-calculator-results/?colors=f4bfdb,bff4f1,f4e6bf
$c-pink: #F4BFDB;
// $c-green: #c1f4bf;
// $c-yellow: #f4f3bf;
// 
$c-cyan: #bff4f1;
$c-yellow: #f4e6bf;



$c-accent: $c-pink;
$c-accent--light: $c-orange--light;


//grid
$grid-columns: 12;
$grid-gutter-width: 30px;


$ease: cubic-bezier(0, 0.25, 0.25, 1);

// z-index
$z-index: (
	cursor    : 100000000,
	modal     : 1200,
	overlay   : 1100,
	header    : 1000,
	footer    : 900,
	dropdown  : 800
);
