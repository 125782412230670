.richtext {
	// @extend .text;

	* {

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	h1 {
		// @extend %h1;

		margin: 1em 0;
	}

	h2 {
		// @extend %h2;

		margin: 1em 0;
	}

	h3 {
		// @extend %h3;

		margin: 1em 0;
	}

	h4 {
		// @extend %h4;
	}

	h5 {
		// @extend %h5;

		margin: 1em 0;
	}

	h6 {
		// @extend %h6;

		margin: 1em 0;
	}

	p {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	ol {

	}

	ul {


		li {

		}
	}

	li {
		margin-bottom: .5em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	b,
	strong {
		font-weight: bold;
	}

	i,
	em {
		font-style: italic;
	}

	img,
	video {
		margin: 1em auto;
	}

	table {
		width: 100%;
	}
}
