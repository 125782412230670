.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: opacity .15s;

	&.is-loading {
		opacity: 0.5;
		pointer-events: none;
		user-select: none;
	}

	&__label {
		width: 100%;
		font-size: 0.8em;
	}

	&__input {
		width: 100%;
		display: block;
		margin: 4px 0 24px;
		padding: 4px 0px;
		// background-color: grey;
		// border-bottom: 1px solid currentColor;
		border-bottom: 1px solid $c-accent;
		transition: border .2s;

		&::placeholder {
			opacity: 0.6;
		}

		&:focus {
			border-bottom: 1px solid darken($c-accent, 15%);
		}
	}

	&__button-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	&__button {
		@extend .btn;

		user-select: none;
		cursor: pointer;
	}

	&__thank {
		max-height: 0;
		opacity: 0;
		width: 100%;
		font-weight: 500;
		text-align: center;
		// padding: 0 28px;
		transition: opacity .1s, margin 0.1s;
		user-select: none;
		pointer-events: none;

		.form.is-thank & {
			opacity: 1;
			max-height: 70px;
			margin-top: 16px;
			transition: opacity .5s, margin .3s;
		}
	}

	&__thank-inner {
	}

	&__thank-text {
	}
}


.loader {
	font-size: 48px;
	width: 1em;
	height: 1em;
	border-radius: 100%;
	overflow: hidden;
	// background-color: $c-accent;

	border: 0.1em solid $c-accent;
	// border-right-color: transparent;
	border-right-color: lighten($c-accent, 20%);

	animation: spin 3s infinite linear;
}