.modal {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	opacity: 0;
	pointer-events: none;
	transition: opacity .3s, transform .3s;
	// user-select: none;

	&--visible {
		opacity: 1;
		pointer-events: all;
		// user-select: none;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: rgba(#000, .5);
		background-color: rgba(#000, .6);
		z-index: 1;
	}

	&__body {
		position: relative;
		z-index: 2;
		// color: #fff;
		width: 500px;
		max-width: 95%;
		background-color: #fff;
		padding: 24px;
		border-radius: 16px;
		transition: opacity .3s;

		transform: translateY(40px) scale(0.96);
		// transform-origin: 50% 0;
		transition: transform .3s;

		.modal--visible & {
			transform: translateY(0) scale(1);
		}
		// opacity: 0;
	}

	&__content {
		transition: opacity .3s;

		.modal--loading & {
			opacity: 0.4;
			pointer-events: none;
			user-select: none;
		}
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		user-select: none;
		display: none;
		pointer-events: none;

		.modal--loading & {
			display: block;
		}
	}

	&__close {
		font-size: 24px;
		position: absolute;
		top: 16px;
		right: 16px;
		width: 1em;
		height: 1em;
		font-size: 24px;
		cursor: pointer;
		user-select: none;
		// background-color: red;

		&:after, &:before {
			content: '';

			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 2px;
			background-color: currentColor;
			opacity: 0.85;
		}
		&:after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

	}

	&__icon-close {
	}

	&__title {
		@extend %h4;

		margin-bottom: 16px;
	}

	&__text {
		line-height: 1.5;
	}

	&__form {
		margin-top: 24px;
	}

	&__thank {
		@extend %text-normal;

		// overflow: hidden;
		
		max-height: 0;
		opacity: 0;
		text-align: center;
		padding: 0 28px;
		transition: opacity .1s, margin 0.1s;
		user-select: none;
		pointer-events: none;

		.modal--thank & {
			opacity: 1;
			max-height: 150px;
			margin-top: 16px;
			transition: opacity .5s, margin .3s;
		}
	}
}
