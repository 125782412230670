.bar {
	--shadow-color: 0deg 0% 63%;
	--shadow-elevation-medium:
		 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
		 0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
		 2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
		 5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
	box-shadow: var(--shadow-elevation-medium);
	position: fixed;
	bottom: 24px;
	right: 24px;
	// width: 100%;
	overflow: hidden;
	// padding: 16px 24px;
	padding: 12px 16px;
	background-color: #fff;
	z-index: 10;
	border-radius: 8px;
	transition: transform .3s;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		--shadow-elevation-medium:
			0px -0.2px 0.2px hsl(var(--shadow-color) / 0.36),
			0px -0.8px 0.9px -0.8px hsl(var(--shadow-color) / 0.36),
			0px -1.9px 2.1px -1.7px hsl(var(--shadow-color) / 0.36),
			0px -4.7px 5.3px -2.5px hsl(var(--shadow-color) / 0.36);
		
		width: 100%;
		max-width: 100%;
		right: 0;
		bottom: 0;
		// border-top: 1px solid black;
		padding: 8px 8px;
		// border-radius: 8px 8px 0 0;
		border-radius: 0;
	}


	&--hidden {
		transform: translateY(130%);
		pointer-events: none;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			transform: translateY(110%);
		}
	}

	&__inner {
		display: flex;
		align-items: center;
	}

	&__media {
		// width: 32px;
	}

	&__figure {
		width: 1em;
		height: 1em;
		border-radius: 10px;
		user-select: none;
		// margin-right: 1em;
		// background-color: #bbb;
		background-color: $c-yellow;
		padding: 0.1em;
		font-size: 48px;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			font-size: 36px;
		}
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		pointer-events: none;
	}

	&__main {
		display: flex;
		align-items: center;
		margin-left: 1em;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-left: 0.8em;
			font-size: 14px;
		}
	}

	&__title {

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			
		}

		span {
			@include lg {
				
			}
			@include md {
				
			}
			@include sm {
				
			}
			@include xs {
				display: none;
			}
		}
	}

	&__price {
		padding: 2px 5px;
		border-radius: 3px;
		background-color: rgba($c-accent, .2);
		border-radius: 4px;
		margin-left: 1em;
		// font-weight: bold;
		font-size: 15px;
	}

	&__button-wrapper {
		margin-left: 24px;

		@include lg {
			
		}
		@include md {
			
		}
		@include sm {
			
		}
		@include xs {
			margin-left: auto;
		}
	}

	&__button {
	}
}
