// https://www.figma.com/file/1ojmzKU5A1B2wvs2w8V9kZ/Maison-Opalore---Master?node-id=616-25748&t=s9AIcicKo1wC6UP0-0

%title {
	font-family: $font-title;
	font-weight: 600;
}

%h1 {
	@extend %title;
	
	font-size: 60px;
	line-height: 1.15;
	letter-spacing: -0.04em;
	

	@include lg {
		font-size: 54px;
	}
	@include md {
		font-size: 48px;
	}
	@include sm {
		font-size: 36px;
	}
	@include xs {
		
	}
}



%h2 {
	@extend %title;
	
	font-size: 36px;
	line-height: 1.15;
	

	@include lg {
		font-size: 28px;
	}
	@include md {
		// font-size: 40px;
	}
	@include sm {
		font-size: 32px;
	}
	@include xs {
		font-size: 24px;
	}
}

%h4 {
	@extend %title;
	
	font-size: 24px;
	letter-spacing: -0.02em;
	
	@include lg {
		font-size: 22px;
	}
	@include md {
		
	}
	@include sm {
		font-size: 20px;
	}
	@include xs {
		
	}
}





%text-mid {
	font-size: 24px;
	line-height: 1.2;

	@include lg {
		font-size: 22px;
	}
	@include md {
		
	}
	@include sm {
		font-size: 20px;
	}
	@include xs {
		font-size: 18px;
	}
}

%text-normal {
	font-size: 20px;
	line-height: 1.2;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		font-size: 18px;
	}
}

%text-small {
	font-size: 16px;

	@include lg {
		
	}
	@include md {
		
	}
	@include sm {
		
	}
	@include xs {
		font-size: 14px;
	}
}


%section-title {
	@extend %h2;
}

.section-title {
	@extend %section-title;

	margin-bottom: 32px;

	&--center {
		text-align: center;
	}
	&--no-margin {
		margin-bottom: 0;
	}
}



%section-subtitle {
	@extend %h4;
}

.section-subtitle {
	@extend %section-subtitle;

	margin-top: 12px;
	margin-bottom: 32px;

	&--center {
		text-align: center;
	}

	&--no-margin {
		margin-bottom: 0;
	}
}

